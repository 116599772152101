import React from "react";
import { isBrowser } from "./src/helpers/isBrowser";
import { detect } from 'detect-browser';

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  const browser = detect();

  return (
    <div className={isBrowser() && browser && browser.name === 'chrome' ? "browser-chrome" : ""}>{element}</div>
  );
}